import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiUrl} from '../helpers/ApiUrl';
import {UserLocation} from '../global-models/user-location.model';

const APIURL = environment;

@Injectable({
  providedIn: 'root'
})
export class UserUtilService {
  public userLocationData = new BehaviorSubject<UserLocation>(null as UserLocation);

  constructor(private http: HttpClient) {
    this.setUserLocationData();
  }

  private setUserLocationData() {
    try {
      const userLocation: UserLocation = JSON.parse(localStorage.getItem('userLocationData'));
      // Get User IP address
      this.getUserIpAddress()
        .subscribe(ipAddress => {
          // Get User location using ip address only if previous and current ip does not match and location data unavailable
          if ((userLocation && userLocation.ip !== ipAddress.ip) || !userLocation) {
            document.cookie = 'Consent' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            this.getUserLocationByIp({
              locale: 'en',
              ip: ipAddress.ip
            }).subscribe(location => {
              const data = {
                ...ipAddress,
                ...location
              };
              localStorage.setItem('userLocationData', JSON.stringify(data));
              this.userLocationData.next(data);
            });
          } else {
            this.userLocationData.next(userLocation);
          }
        }, error => {
        });
    } catch (e) {
    }
  }

  private getUserIpAddress(): Observable<{ ip: string }> {
    const getMyIp = 'http://localhost:3000/v1/ip';
    const URL = APIURL.isLocalEnv ? getMyIp : ApiUrl.GET_USER_IP_ADDRESS;
    return this.http.get<{ ip: string }>(URL);
  }

  private getUserLocationByIp(data: {
    ip: string,
    locale: string
  }): Observable<any> {
    return this.http.get(APIURL.whereUserIs, {params: data});
  }
}
