import firebase from 'firebase/app';

import 'firebase/app-check';

import {environment} from 'src/environments/environment';

const app = firebase.initializeApp(environment.firebase);
const appCheck = app.appCheck();

appCheck.activate(environment.appCheckSiteKey, true);
