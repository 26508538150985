import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {UtilService} from './util.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from 'firebase';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Organization} from '../global-models/organization.model';
import {PulseUser} from '../global-models/pulse-user.model';
import {DecodedIdToken} from '../global-models/idToken.model';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class SharedAuthService {
  USER = 'users';
  ORGANIZATION = 'organizations';
  private currentUserFirestoreDataSubject = new BehaviorSubject<PulseUser>(null);
  currentUserFirestoreData = this.currentUserFirestoreDataSubject.pipe(filter(currentUser => currentUser !== null));

  private ORGANIZATIONS = 'organizations';
  private organization = new BehaviorSubject<Organization>(null);
  getCurrentOrganization = this.organization.pipe(filter(currentOrg => currentOrg !== null));

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private utilService: UtilService,
    private router: Router
  ) {
  }

  getCurrentUserRole(): Promise<number> {
    return new Promise((resolve) => {
      this.getCurrentUserDecodedIdToken().subscribe((decodedToken: DecodedIdToken) => {
        resolve(Number(decodedToken.custom_claims.role));
      });
    });
  }

  private getCurrentUserIdToken(): Observable<any> {
    return this.angularFireAuth.idToken;
  }

  isUserLoggedIn() {
    return new Promise((resolve, reject) => {
      return this.angularFireAuth.authState.subscribe(currentAuthUser => {
        resolve(!!currentAuthUser);
      });
    });
  }

  doLoginUsingPassword(credentials): Promise<UserCredential> {
    return this.angularFireAuth
      .signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  doLogout(): void {
    this.angularFireAuth.signOut().then(() => {
      localStorage.removeItem('currentEnvironment');
      this.currentUserFirestoreDataSubject.next(null);
      this.organization.next(null);
      this.router.navigateByUrl('');
    });
  }

  forgotPassword(email: string) {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  getCurrentUserDecodedIdToken(): Observable<DecodedIdToken> {
    return new Observable<DecodedIdToken>(obs => {
      this.getCurrentUserIdToken().subscribe(idToken => {
        if (idToken) {
          const decodedToken: DecodedIdToken = this.utilService.decodeJWTToken(idToken);
          obs.next(decodedToken);
        }
      });
    });
  }

  getCurrentOrganizationId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getCurrentUserDecodedIdToken().subscribe(decodeToken => {
        resolve(decodeToken?.custom_claims?.organization);
      });
    });
  }

  isCurrentUserPartner(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.getCurrentUserDecodedIdToken().subscribe((decodedToken: DecodedIdToken) => {
        obs.next(!!decodedToken?.custom_claims?.is_partner);
      });
    });
  }

  async setCurrentUserFirestoreData(currentUserId: string = null) {
    if (!currentUserId) {
      currentUserId = await this.getCurrentUserIdAsync();
    }
    this.angularFirestore.collection(this.USER).doc(currentUserId)
      .get().subscribe((firestoreUserData: any) => {
      const currentUser: PulseUser = firestoreUserData.data();
      if (currentUser?.org_id) {
        this.angularFirestore.collection(this.ORGANIZATION).doc(currentUser?.org_id)
          .get().subscribe((userOrganization: any) => {
          currentUser.organization = userOrganization.data();
          this.currentUserFirestoreDataSubject.next(currentUser);
        }, error => {
          console.log('error while accessing organization collection', error);
        });
      } else {
        this.currentUserFirestoreDataSubject.next(currentUser);
      }
    }, error => {
      console.log('error while accessing user collection', error);
    });
  }

  setCurrentUserOrganization() {
    this.getCurrentOrganizationId()
      .then(organizationId => {
        if (organizationId) {
          this.angularFirestore.collection(this.ORGANIZATIONS)
            .doc(organizationId).get()
            .subscribe((userOrganization: any) => {
              this.organization.next(userOrganization.data());
            });
        }
      });
  }

  getCurrentUserIdSync(): string | null {
    return firebase.auth()?.currentUser?.uid ?? null;
  }

  private getCurrentUserIdAsync(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.authState.subscribe(fireAuthUser => {
        if (fireAuthUser) {
          resolve(fireAuthUser.uid);
        }
      });
    });
  }
}
