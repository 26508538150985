import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';


import {AdminService} from './modules/admin/core/admin.service';
import {BoardingPassesComponent} from './modules/admin/boarding-passes/boarding-passes.component';
import {NotFoundComponent} from './modules/shared/modules/not-found/not-found.component';
import {DatePipe} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule, Routes} from '@angular/router';
import {AppUnavailableInGuard} from './shared-global/routeGuard/app-unavailable-in.guard';
import {AppAvailableInGuard} from './shared-global/routeGuard/app-available-in.guard';
import './firebase-init';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppCheckTokenInterceptor} from './shared-global/interceptor/app-check-token.interceptor';
import {MaintenanceOffRouteGuard} from './shared-global/routeGuard/maintenance/maintenance-off-route.guard';
import {MaintenanceOnRouteGuard} from './shared-global/routeGuard/maintenance/maintenance-on-route.guard';
import {SharedAuthService} from './shared-global/shared-services/shared-auth.service';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppUnavailableInGuard, MaintenanceOnRouteGuard],
    loadChildren: () => import('./app-routing.module').then(m => m.AppRoutingModule),
  },
  {
    path: 'unavailable',
    canActivate: [AppAvailableInGuard],
    loadChildren: () => import('./modules/shared/modules/app-unavailable/app-unavailable.module').then(m => m.AppUnavailableModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./modules/shared/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [MaintenanceOffRouteGuard]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  declarations: [
    AppComponent,
    BoardingPassesComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true,
    }),
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    AppUnavailableInGuard,
    AppAvailableInGuard,
    SharedAuthService,
    AdminService,
    DatePipe,
    MaintenanceOffRouteGuard,
    MaintenanceOnRouteGuard,
    {provide: HTTP_INTERCEPTORS, useClass: AppCheckTokenInterceptor, multi: true}, // http interceptor (inject bearer token)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
