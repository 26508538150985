import {Injectable} from '@angular/core';
/*import firebase from 'firebase';*/
/*import {UserUtilService} from './user-util.service';*/
/*import {MaintenanceMode} from '../shared.model';*/

@Injectable({
  providedIn: 'root'
})
export class SharedRemoteConfigService {


  constructor() {
  }

  isMaintenanceMode() {
    return false;
    /*return new Promise(async (resolve, reject) => {
      try {
        this.userUtilService.userLocationData.subscribe(async userLocationData => {
          if (userLocationData) {
            const remoteConfig = firebase.remoteConfig();
            remoteConfig.settings.minimumFetchIntervalMillis = 3600;
            await remoteConfig.fetchAndActivate();
            const maintenanceMode: MaintenanceMode = JSON.parse(remoteConfig.getValue('maintenance_mode').asString());
            const isMaintenanceMode = maintenanceMode.pulse.markets.some(maintenanceCountry => {
              return maintenanceCountry.country_name.toLowerCase().includes(userLocationData.country_name.toLowerCase())
                && maintenanceCountry.maintenance_mode;
            });
            resolve(isMaintenanceMode);
          }
        });
      } catch (e) {
        resolve(false);
      }
    });*/
  }
}
