import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserUtilService} from '../shared-services/user-util.service';
import {FAREUPTHERE_AVAILABLE_IN_COUNTRIES} from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class AppUnavailableInGuard implements CanActivate {
  constructor(
    private userUtilService: UserUtilService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>(obs => {
      this.userUtilService.userLocationData.subscribe(userLocationData => {
        if (userLocationData) {
          if (FAREUPTHERE_AVAILABLE_IN_COUNTRIES.includes(userLocationData.country_name.toLowerCase())) {
            obs.next(true);
          } else {
            this.router.navigate(['/unavailable']);
            obs.next(false);
          }
        }
      });
    });
  }

}
