import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpEvent
} from '@angular/common/http';
import firebase from 'firebase/app';
import {forkJoin, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

const APIURL = environment;

@Injectable()
export class AppCheckTokenInterceptor implements HttpInterceptor {
  VALID_HOST = [
    'localhost',
    'api-staging.meander.travel',
    'api.meander.travel'
  ];

  constructor() {
  }


  // This function determines whether a token should be added to the request header based on the URL being requested.
  private shouldAddToken(url: string): boolean {
    return url.startsWith(environment.FareUpThereAPI);
  }

  // This function intercepts HTTP requests and adds a token to the request header if necessary.
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if token should be added
    if (!this.shouldAddToken(req.url)) {
      return next.handle(req); // If the token doesn't need to be added, pass the original request to the next handler
    }
    try {
      return forkJoin({
        // Get Firebase App Check token
        appCheckToken: firebase.appCheck().getToken(),
        // Get the current user from Firebase Auth
        idToken: new Promise(resolve => {
          firebase.auth().onAuthStateChanged(auth => {
            try {
              auth.getIdToken(true).then((idToken: string) => {
                resolve(idToken);
              }).catch(() => {
                resolve(null);
              });
            } catch (e) {
              resolve(null);
            }
          });
        })
      }).pipe(
        switchMap(({appCheckToken, idToken}) => {
          // Define headers to be added to the request
          const headers: any = {
            token: appCheckToken.token,
            'x-platform': 'Pulse',
            environment: APIURL.production ? 'production' : 'staging'
          };
          // If there is a current user, get their ID token and add it to the headers
          if (idToken) {
            headers.Authorization = 'Bearer ' + idToken;
            // Clone the original request and add the headers to the clone
            const tokenReq = req.clone({setHeaders: headers});
            // Return the modified request
            return next.handle(tokenReq);
          }

          // If there is no current user, just add the headers to the original request
          const tokenReq = req.clone({setHeaders: headers});
          // Return the modified request
          return next.handle(tokenReq);
        })
      );
    } catch (e) {
      console.log(e);
    }

  }


  /*  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.VALID_HOST.includes(this.getHost(req.url))) { // Send token in header only for backend API
        return from(firebase.appCheck().getToken())
          .pipe(
            switchMap(appCheckTokenResponse => {

              /!*console.log(appCheckTokenResponse.token);*!/
              const tokenReq = req.clone({
                setHeaders: {
                  token: appCheckTokenResponse.token,
                  environment: APIURL.production ? 'production' : 'staging'
                }
              });
              return next.handle(tokenReq);
            })
          );
      } else {
        return next.handle(req);
      }
    }

    getHost(givenUrl) {
      let url;
      try {
        url = new URL(givenUrl);
      } catch (e) {
        return false;
      }
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        return url.hostname;
      }
    }*/
}
