import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SharedRemoteConfigService} from '../../shared-services/shared-remote-config.service';


@Injectable({
  providedIn: 'root'
})

export class MaintenanceOnRouteGuard implements CanActivate {
  constructor(
    public sharedRemoteConfigService: SharedRemoteConfigService,
    private router: Router) {
  }


  async canActivate() {
    const isMaintenanceMode = await this.sharedRemoteConfigService.isMaintenanceMode();
    if (isMaintenanceMode) {
      this.router.navigate(['/maintenance']);
      return false;
    } else {
      return true;
    }
  }


}
