import {Component, OnInit} from '@angular/core';
import {SharedAuthService} from './shared-global/shared-services/shared-auth.service';
import {UtilService} from './shared-global/shared-services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'FareUpThere';

  constructor(private sharedAuthService: SharedAuthService,
              private utilService: UtilService
  ) {
  }

  ngOnInit() {
    this.sharedAuthService.setCurrentUserOrganization();
    this.sharedAuthService.setCurrentUserFirestoreData();
    this.utilService.addBranchIo();
  }
}
