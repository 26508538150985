import 'firebase/firestore';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  USERS = 'users';
  RATINGS = 'ratings';
  REVIEWS = 'reviews';
  AIRLINES = 'airlines';

  constructor(
    private database: AngularFirestore
  ) {
  }


  // this is for home page boarding passes count

  fetchCountForboardingPasses() {

    return new Promise<any>((resolve, reject) => {

      try {
        const Rdataa = this.database.collection<any>(this.REVIEWS);
        const dataaa = Rdataa.snapshotChanges()
          .pipe(
            map((docs) =>
              docs.map((doc) => {
                const data = doc.payload.doc.data();
                data['id'] = doc.payload.doc.id;
                return data;
              })
            )
          );

        const Rdata = this.database.collection<any>(this.RATINGS);
        const dataa = Rdata.snapshotChanges()
          .pipe(
            map((docs) =>
              docs.map((doc) => {
                const data = doc.payload.doc.data();
                data['id'] = doc.payload.doc.id;
                return data;
              })
            )
          );
        combineLatest([dataa, dataaa])
          .subscribe(a => {
            const b = a[1].concat(a[0]);
            let deniedPass = 0;
            let approvedPass = 0;
            let pendingPass = 0;
            b.forEach(element => {
              if (element.verified === false) {
                deniedPass = deniedPass + 1;
              } else if (element.verified === true) {
                approvedPass = approvedPass + 1;
              } else if (element.verified === 'pending' || element.verified === 'Pending') {
                pendingPass = pendingPass + 1;
              }
            });

            const finalData = {
              denied: deniedPass,
              approved: approvedPass,
              pending: pendingPass
            };

            resolve(finalData);

          });
      } catch (error) {
        reject(error);
      }
    });
  }

  // boarding pass screen

  getRatingsForBoardingPasses() {
    const Rdata = this.database.collection<any>(this.RATINGS
      , ref =>
        ref.where('verified', 'in', ['pending', 'Pending'])
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const user = [];

            this.database.collection<any>(this.USERS, ref =>
              ref.where('user_id', '==', ratings.user_id))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(aa => {
                    const Data = aa.payload.doc.data();
                    const id = aa.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                user.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const finalData = {
              ...ratings,
              airline_details: airline,
              user_detail: user
            };

            return finalData;
          })
        )
      );
    return dataa;

  }


  getReviewsForBoardingPasses() {
    const Rdata = this.database.collection<any>(this.REVIEWS, ref =>
      ref.where('verified', 'in', ['pending', 'Pending'])
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const finalData = {
              ...ratings,
              airline_details: airline
            };

            return finalData;
          })
        )
      );
    return dataa;

  }


  approveRating(id: string) {
    return new Promise<any>((resolve4, reject) => {
      this.database
        .collection(this.RATINGS)
        .doc(id)
        .update({verified: true})
        .then(
          (res) => {
            resolve4(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  approveReviews(id: string) {
    return new Promise<any>((resolve4, reject) => {
      this.database
        .collection(this.REVIEWS)
        .doc(id)
        .update({verified: true})
        .then(
          (res) => {
            resolve4(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }


  denyRating(id: string) {
    return new Promise<any>((resolve4, reject) => {
      this.database
        .collection(this.RATINGS)
        .doc(id)
        .update({verified: false, boardingPassImage: ''})
        .then(
          (res) => {
            resolve4(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  denyReviews(id: string) {
    return new Promise<any>((resolve4, reject) => {
      this.database
        .collection(this.REVIEWS)
        .doc(id)
        .update({verified: false})
        .then(
          (res) => {
            resolve4(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }


  fetchDeniedReviews() {
    const Rdata = this.database.collection<any>(this.REVIEWS, ref =>
      ref.where('verified', '==', false)
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });
            const finalData = {
              ...ratings,
              airline_details: airline
            };
            return finalData;
          })
        )
      );
    return dataa;
  }


  fetchPendingReviews() {
    const Rdata = this.database.collection<any>(this.REVIEWS, ref =>
      ref.where('verified', 'in', ['pending', 'Pending'])
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });
            const finalData = {
              ...ratings,
              airline_details: airline
            };
            return finalData;
          })
        )
      );
    return dataa;
  }


  fetchApprovedRatings() {
    const Rdata = this.database.collection<any>(this.RATINGS
      , ref =>
        ref.where('verified', '==', true)
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const user = [];

            this.database.collection<any>(this.USERS, ref =>
              ref.where('user_id', '==', ratings.user_id))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(aa => {
                    const Data = aa.payload.doc.data();
                    const id = aa.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                user.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const finalData = {
              ...ratings,
              airline_details: airline,
              user_detail: user
            };

            return finalData;
          })
        )
      );
    return dataa;
  }


  fetchDeniedRatings() {
    const Rdata = this.database.collection<any>(this.RATINGS
      , ref =>
        ref.where('verified', '==', false)
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const user = [];

            this.database.collection<any>(this.USERS, ref =>
              ref.where('user_id', '==', ratings.user_id))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(aa => {
                    const Data = aa.payload.doc.data();
                    const id = aa.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                user.push(JSON.parse(JSON.stringify(a.name)));
              });
            });
            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const finalData = {
              ...ratings,
              airline_details: airline,
              user_detail: user
            };

            return finalData;
          })
        )
      );
    return dataa;
  }


  fetchPendingRatings() {
    const Rdata = this.database.collection<any>(this.RATINGS
      , ref =>
        ref.where('verified', 'in', ['pending', 'Pending'])
    );
    const dataa = Rdata.snapshotChanges()
      .pipe(
        map((docs) =>
          docs.map((doc) => {
            const data = doc.payload.doc.data();
            data['id'] = doc.payload.doc.id;
            const ratings = JSON.parse(JSON.stringify(data));
            const user = [];

            this.database.collection<any>(this.USERS, ref =>
              ref.where('user_id', '==', ratings.user_id))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(aa => {
                    const Data = aa.payload.doc.data();
                    const id = aa.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                user.push(JSON.parse(JSON.stringify(a.name)));
              });
            });
            const airline = [];
            this.database.collection<any>(this.AIRLINES, ref =>
              ref.where('code', '==', ratings.airlineId))
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const Data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...Data};
                  })
                )
              ).subscribe((item) => {
              item.map(a => {
                airline.push(JSON.parse(JSON.stringify(a.name)));
              });
            });

            const finalData = {
              ...ratings,
              airline_details: airline,
              user_detail: user
            };

            return finalData;
          })
        )
      );
    return dataa;
  }

}
